import axios from 'axios'
import { uri } from '../../uri'
import { dispatchRequest } from '../helpers'
import { getToken, setToken } from '../token'
import { refreshToken } from '../auth/actions'
import config from '../../config'

const getConfig = () => ({
  headers: { Authorization: `Bearer ${getToken('mfc-token')}` }
})

export const unlockVideo = (purchaseId, session) => (dispatch) => {
  dispatch({
    type: 'UNLOCK_VIDEO'
  })

  axios.post(`${uri.uri.user.unlockVideo}`, { purchaseId, videoUrl: session }, getConfig()).then((response) => {
    dispatch({
      type: 'UNLOCK_VIDEO_SUCCESS',
      data: response
    })

    axios.post(`${uri.uri.user.refreshToken}`, {}, getConfig()).then((response) => {
      setToken('mfc-token', response.data.token)
      dispatch({
        type: 'GET_ACCOUNT_SUCCESS',
        data: response
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_ACCOUNT_FAIL',
        data: null
      })
    })
  }).catch((error) => {
    dispatch({
      type: 'UNLOCK_VIDEO_ERROR',
      data: error
    })
  })
}

export const downloadDoc = pdf => (dispatch) => {


  // TO REVIEW
  const filePath = `${config.apiUrl}/pdf/${pdf}?token=${getToken('mfc-token')}`

  const win = window.open(filePath, '_blank')
  win.focus()
  /* const link = document.createElement('a')
  link.href = filePath
  link.setAttribute('target', '_blank')

  if (link.download !== undefined) {
    link.download = pdf
  }

  if (document.createEvent) {
    const e = document.createEvent('MouseEvents')
    e.initEvent('click', true, true)
    link.dispatchEvent(e)
    return true
  } */
}
