import React, { Component } from 'react'
import './stylesheet.scss'
import {Link, withRouter} from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import classNames from 'classnames'
import logo from '../../image/logo-mfc-couleur.png'
import justhibault from '../../image/justhibault2.png'
import {Button, Modal} from 'antd'
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
    this.facebookLoginHandler = this.facebookLoginHandler.bind(this)
  }

  logoutFb = () => {
    const { cookies } = this.props
    cookies.remove('mfc-token')
    cookies.remove('facebook-user')
    if (window.FB) {
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          window.FB.logout((resp) => {
            localStorage.clear()
            const cookies = document.cookie.split(';')

            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i]
              const eqPos = cookie.indexOf('=')
              const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
              document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
            }
            window.location.reload()
          })
        } else {
          this.setState({showModal: false})
          window.location.reload()
        }
      })
    } else {
      this.setState({showModal: false})
      window.location.reload()
    }
  }

  componentWillMount() {
    const {
      fbConnect, cookies, refreshToken
    } = this.props

    const token = cookies.get('mfc-token')
    if (cookies.get('mfc-token')) {
      refreshToken(token)
    } else {
      window.FB && window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          window.FB.api('/me?fields=name,email,picture', (userData) => {
            const result = {
              ...response,
              user: userData
            }
            fbConnect(true, result)
          })
        } else {
          // window.FB.login(this.facebookLoginHandler, { scope: 'public_profile' })
        }
      })
    }
  }

  facebookLoginHandler = (response) => {
    const { fbConnect } = this.props
    if (response.status === 'connected') {
      window.FB && window.FB.api('/me?fields=name,email,picture', (userData) => {
        const result = {
          ...response,
          user: userData
        }
        fbConnect(true, result)
      })
    }
  }

  connectWithRedirect = () => {
    window.FB && window.FB.login(this.facebookLoginHandler, { scope: 'email' })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if( !this.props.account && nextProps.account && (document.getElementsByTagName('body') && document.getElementsByTagName('body')[0].offsetWidth > 749) ){
      this.setState({ showModal: true })
    }
  }


  modalContent = () => {
    const { account, history, refreshToken } = this.props
    return (
        <>
          <div className="main-container__right__button__resume">
            <div className="main-container__right__button__resume__avatar">
              <img src={account.user.avatar || 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png'}/>
            </div>
            <div className="main-container__right__button__resume__user">
              {account.user.name}
            </div>
          </div>
          <div className="main-container__right__button__confirmation">
            Est-ce bien ton compte? Est-ce bien celui avec lequel tu te connectes d'habitude ?
          </div>
          <div className="main-container__right__button__actions">
            <div
                className="main-container__right__button__actions__yes"
                onClick={() => {
                  refreshToken()
                  history.push('/my-fiit-challenges')
                }}
            >
              OUI
            </div>
            <div
                className="main-container__right__button__actions__no"
                onClick={() => {
                  this.logoutFb()
                }}
            >
              NON
            </div>
          </div>
          {
            !account ? (
                <div className="facebook-advertise">
                  Un compte facebook est obligatoire
                </div>
            ) : (
                <Link to="/help" className="main-container__right__help" onClick={() => history.push('/help')}>
                  Comment changer de compte <FontAwesomeIcon icon={faQuestionCircle} />
                </Link>
            )
          }
          <Link to="/help" className="main-container__right__help" onClick={() => history.push('/politique-de-confidentialite')}>
            Politique de confidentialité  <FontAwesomeIcon icon={faQuestionCircle} />
          </Link>
        </>
  )}

  renderModalFb = () => {
    const { account, history } = this.props

    return (
        <Modal
            visible
            closable
            onCancel={() => this.setState({ showModal: false })}
            className="main-container__modal"
        >
          <div className="main-container__right__container main-container__right__container--modal">
            <div className="main-container__right__logo">
              <img src={logo} />
            </div>
            <div className="main-container__right__connect-sentence">
              ATTENTION ! Tu tentes de te connecter avec ce compte facebook:
            </div>
            { this.modalContent()}
        </div>
        </Modal>
    )
  }
  render() {
    const { account, history } = this.props

    return (
      <div className="main-container">
        <div
          className={classNames({
            'main-container__left': true,
            'main-container__left__connected': account
          })}
          style={{ backgroundSize: 'cover' }}
        />
        <div className={classNames({
          'main-container__right': true,
          'main-container__right__connected': account
        })}
        >
          <div className="main-container__right__container">
            <div className="main-container__right__title">
              Espace personnel
            </div>
            <div className="main-container__right__logo">
              <img src={logo} />
            </div>
            <div className="main-container__right__connect-sentence">
              {
                account ? 'ATTENTION ! Tu tentes de te connecter avec ce compte facebook.' : 'Connecte toi et rejoins la communauté MyFIITChallenge'
              }
            </div>
            {
              !account || this.state.showModal && (
              <div className="main-container__right__connect-sub">
                    Connecte toi avec le compte facebook en cliquant sur ce bouton et accède à ton espace personnel.
                    Tu pourras ensuite avoir accès à tous tes programmes préférés.
              </div>
              )
            }

            <div className="main-container__right__button">
              {
                  !account ? (
                      <>
                        <button onClick={() => this.connectWithRedirect()} className="main-container__right__button__fb">
                          <FontAwesomeIcon icon={faFacebook} />Continuer avec Facebook
                        </button>
                        <div className="facebook-advertise__home">
                          Un compte facebook est obligatoire
                        </div>
                      </>
                  )
                    : (
                       this.state.showModal ? this.renderModalFb() : this.modalContent()
                    )
                }
            </div>
            {
              !account && (
                  <Link to="/help" className="main-container__right__facebook">
                    Besoin d'aide <FontAwesomeIcon icon={faQuestionCircle} />
                  </Link>
              )
            }
            <div style={{marginTop: 20}}>
              <Link to="/politique-de-confidentialite" className="main-container__right__facebook">
                Politique de confidentialité
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withCookies(withRouter(Index))
