import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBroadcastTower, faArrowRight, faRedoAlt, faPowerOff, faExclamationCircle, faPencilAlt, faUserFriends
} from '@fortawesome/free-solid-svg-icons'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'
import { getMfcs } from '../../redux/admin/action'
import BreadcrumbDesktop from '../../components/breadcrumbDesktop'
import doubleflex from '../../image/poings.jpg'
import mfc4 from '../../image/mfc4r.jpg'
import mfcSummer from '../../image/mfcsummer.jpg'
import mfcSummer2 from '../../image/mfcss2.jpg'
import mfc3 from '../../image/mfc3r.jpg'
import mfc5 from '../../image/mfc5flexmini.jpg'
import fire from '../../image/fire.png'
import edit from '../../image/editIcon.png'
import { renderPrice } from '../../helpers/utility'

library.add(faBroadcastTower, faArrowRight, faRedoAlt, faPowerOff)

class MfSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boxWidth: null,
      boxHeight: null,
      purchasedItems: [],
      availableItems: []
    }
  }

  componentWillMount() {
    const { refreshToken } = this.props
    refreshToken()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    // const { getMfcs } = this.props
    // getMfcs()
  }

  setRatio = () => {
    const large = document.getElementsByClassName('mfc__left__content')[0]

    if (large && large.offsetWidth) {
      const adaptedWidth = (large.offsetWidth > 600) ? 550 : large.offsetWidth
      this.setState({ boxWidth: adaptedWidth, boxHeight: adaptedWidth / 1.8 })
    }
  }

  renderMfcImage = (name, edition) => {
    if (edition === 45 || edition === '45') {
      return mfcSummer
    }
    if (edition === 55 || edition === '55') {
      return mfcSummer2
    }
    switch (name) {
      case 'MFC4 Live':
        return mfc4
      case 'MFC4 Replay':
        return mfc4
      case 'MFC3 Replay':
        return mfc3
      case 'MFC5':
        return mfc5
      case 'MFC 5':
        return mfc5
      default:
        return mfc4
    }
  }

  allowDisplay = (mfc) => {
    const { account } = this.props
    if (mfc.hidden) {
      return account.user.role === 'ADMIN'
    }
    return true
  }

  isAdmin = () => {
    const { account } = this.props
    return account.user.role === 'ADMIN'
  }

  freeToBuy = (mfc) => {
    return !mfc.blocked
  }

  userDoesntHaveLive = (mfc) => {
    const { account } = this.props
    if (account && account.newPurchases && account.newPurchases.find(p => p.edition === mfc.edition)) {
      return false
    }

    return true
  }

  renderFreeze = () => (
    <div className="mfc__close">
      <div className="mfc__close__icon">
        <FontAwesomeIcon icon={faExclamationCircle} />
      </div>
      <div className="mfc__close__text">
            La ventes des programmes est actuellement suspendue, plus d'infos <a href="https://www.myfiitchallenge.com" rel="noopener noreferrer" target="_blank">ici</a>
      </div>
    </div>
  )

   renderHighlighted = (mfc, index, no) => (
     <div className="mfc__row__large" style={{ minHeight: (this.state.boxHeight > 250) ? this.state.boxHeight : 300 || 'inherit', width: this.state.boxWidth || 'inherit' }} key={`mfc-${index}`} onClick={() => this.props.history.push(no ? `challenge/${mfc.name}` : `my-fiit-challenge/${mfc.name}`)}>
       <div className="mfc__row__large__container">
         <div className="mfc__row__large__container__left">
           <div className="mfc__row__large__container__left__date">
             {mfc.from && mfc.to
               ? <>Du {mfc.from} {mfc.fromMonth} au {mfc.to} {mfc.toMonth}</> : <></>
             }
           </div>
           <div className="mfc__row__large__container__left__title">{mfc.name}</div>
           <div className="mfc__row__large__container__left__text">{ mfc.isDuo ? <>1 <span>PLACE</span> ou <span>2 PLACES</span></> : ''}
             { mfc.promoSentence }
           </div>
         </div>

         {
           mfc.isPromo && (
             <div className="mfc__row__large__container__right">
               <div className="mfc__row__large__container__right__circle">
                 PROMO LIMITÉE
               </div>
             </div>
           )
         }
       </div>
       <div className="mfc__row__large__container__left__action">
         <div className="mfc__row__large__container__left__action__button">
           <React.Fragment>INSCRIS-TOI MAINTENANT <FontAwesomeIcon icon={faArrowRight} /></React.Fragment>
         </div>
       </div>
     </div>
   );

  renderPrices = (oldPrice, price) => {
    return (
      <>
        {oldPrice !== price ? (
          <>
            <div className="mfc__row--offer mfc__row--offer--full mfc__row--offer--no-padding">
              <div className="mfc__row--offer__prices mfc__row--offer--no-padding__prices">
                <div className="mfc__row--offer__prices__old">
                  <div className="mfc__row--offer__prices__old__dash" />
                  { renderPrice(oldPrice) } €
                </div>
                <div className="mfc__row--offer__prices__new">{ renderPrice(price) } €</div>
              </div>
              <div className="mfc__row--offer__label">
                Profiter de l'offre <FontAwesomeIcon icon="arrow-right" />
              </div>
            </div>
          </>

        ) : (
          <div className="mfc__row--offer mfc__row--offer--no-padding">
            <div className="mfc__row--offer__prices mfc__row--offer--no-padding__prices">
              <div className="mfc__row--offer__prices__new">{renderPrice(price)} €</div>
            </div>
            <div className="mfc__row--offer__label">
              Profiter de l'offre <FontAwesomeIcon icon="arrow-right" />
            </div>
          </div>
        )}
      </>
    )
  }

renderModeDuo = (account) => {
  const { promoCodes } = account

  if (promoCodes && promoCodes.length > 0 && promoCodes[promoCodes.length - 1]) {
    return (
      <div className="mfc__row__container__join mfc__row__container__join--no-hover">
        <div className="mfc__row__container__join__left">
          <FontAwesomeIcon className="mfc__row__container__join__left__1" icon={faUser} />
          <FontAwesomeIcon className="mfc__row__container__join__left__2" icon={faUser} />
        </div>
        <div className="mfc__row__container__join__middle mfc__row__container__join__middle--bold">
            Vous avez un code à transmettre à votre partenaire
          <span>
            {
              promoCodes[promoCodes.length - 1].code
            }
          </span>
        </div>
        <div className="mfc__row__container__join__right">
          <div className="mfc__row__container__join__right__container">
            <FontAwesomeIcon icon={faPencilAlt} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="mfc__row__container__join" onClick={() => this.props.history.push('join-team')}>
      <div className="mfc__row__container__join__left">
        <FontAwesomeIcon className="mfc__row__container__join__left__1" icon={faUser} />
        <FontAwesomeIcon className="mfc__row__container__join__left__2" icon={faUser} />
      </div>
      <div className="mfc__row__container__join__middle">
        <div className="mfc__row__container__join__middle__top">
            Ton Partenaire t'a envoyé un code pour une inscription ?
        </div>
        <div className="mfc__row__container__join__middle__bottom">
            À renseigner ici uniquement par la personne qui n'a pas effectué l'achat
        </div>
      </div>
      <div className="mfc__row__container__join__right">
        <div className="mfc__row__container__join__right__container">
          <FontAwesomeIcon icon={faPencilAlt} />
        </div>
      </div>
    </div>
  )
}

  renderClassic = (mfc, index, isLive) => (
    <div className={`mfc__row ${isLive ? 'mfc__row--replay' : 'mfc__row--replay'}`} key={`mfc-${index}`} onClick={() => this.props.history.push(!isLive ? `/purchase/${mfc.name}/${mfc._id}` : `challenge/${mfc.name}`)}>
      <div className="mfc__row__container">
        <div className={`mfc__row__container__left ${this.renderMfcImage(mfc.name, mfc.edition)}`} />
        <div className="mfc__row__container__right">
          <div className="mfc__row__container__right__top">
            <div className="mfc__row__container__right__top__label">
              { mfc.name }
            </div>
            <div className="mfc__row__container__right__top__label__type">
              version { mfc.isReplay ? 'Replay' : 'Live' }
            </div>
            <div className="mfc__row__container__right__bottom__text">
              { mfc.homeSentence }
            </div>
            { this.renderPrices(mfc.oldPrice, mfc.price) }
          </div>
        </div>
      </div>
    </div>
  );

  renderPaid = (mfc, index, df, own) => (
    <div
      onClick={() => this.props.history.push(own ? `my-fiit-challenge/${mfc.name}` : mfc.isDuo ? `/challenge/${mfc.name}/${mfc._id}` : `/purchase/${mfc.name}/${mfc._id}`)}
      className={classNames({
        new__mfc__row: true,
        new__mfc__row__reverse: !own
      })}
      key={`mfc-${index}`}
    >
      <div className="new__mfc__row__image">
        <img src={this.renderMfcImage(mfc.name, mfc.edition)} />
      </div>
      <div className="new__mfc__row__right">
        <div className="new__mfc__row__right__name">
          { mfc.name }
        </div>
        <div className="new__mfc__row__right__version">
            version { mfc.isReplay ? 'Replay' : 'Live' }
        </div>
        <div className="new__mfc__row__right__level">
          { mfc.edition === 4 && 'Débutant / intermediaire' }
          { mfc.edition === 45 && 'Intermediaire / confirmé' }
          { mfc.edition === 55 && 'Intermediaire / confirmé' }
        </div>
        <div className={classNames({
          new__mfc__row__right__action: true,
          new__mfc__row__right__action__reverse: !own
        })}
        >
          { own ? 'Accéder au challenge' : "Profiter de l'offre" }
        </div>
      </div>
    </div>
  );

  renderPaidHighligth = (mfc, index, df, own) => (
    <div
      onClick={() => this.props.history.push(own ? `my-fiit-challenge/${mfc.name}` : mfc.isDuo ? `/challenge/${mfc.name}/${mfc._id}` : `/purchase/${mfc.name}/${mfc._id}`)}
      className={classNames({
        new__mfc__row: true,
        new__mfc__row__highlight: true,
      })}
      key={`mfc-${index}`}
    >
      <div className="new__mfc__row__highlight__image">
        <div className="new__mfc__row__highlight__image__dots">
          <div className="new__mfc__row__highlight__image__dots__dot"><img src={fire} /></div>
          <div className="new__mfc__row__highlight__image__dots__dot"><img src={fire} /></div>
          <div className="new__mfc__row__highlight__image__dots__dot"><img src={fire} /></div>

        </div>

      </div>
      <div className="new__mfc__row__highlight__right">
        <div className="new__mfc__row__highlight__right__name">
          { mfc.name }
        </div>
        <div className="new__mfc__row__right__version">
          {(mfc.edition !== 45 && mfc.edition !== 55) ? `L'édition N°${mfc.edition}` : 'Seconde édition!'}
        </div>
        <div className="new__mfc__row__right__bottom">
          <div className="new__mfc__row__right__prices">
            {mfc.oldPrice !== mfc.price && <div className="new__mfc__row__right__prices__old">{mfc.oldPrice / 100}€</div>}
            <div className="new__mfc__row__right__prices__new">{mfc.price / 100}€</div>
          </div>
          <div className="new__mfc__row__right__action new__mfc__row__highlight__right__action">
            { own ? 'Accéder au challenge' : "Profiter de l'offre" }
          </div>
        </div>
      </div>
    </div>
  );

  renderMfc = (mfc, index, paid) => {
    // const found = !!purchases.filter(p => p.mfc._id === mfc._id).length
    // const found = (!!purchases.filter(p => p.mfc === mfc._id).length) || (purchases.find(p => (p.mfc && p.mfc._id) === mfc._id))

    if (!paid) {
      if (mfc.highlighted) {
        // return this.renderHighlighted(mfc, index, 'no')
        return this.renderPaidHighligth(mfc, index, null, false)
      }
      return this.renderPaid(mfc, index, !mfc.isReplay, false)
      // return this.renderClassic(mfc, index, !mfc.isReplay)
    }
    if (!mfc.isReplay) {
      return this.renderPaid(mfc, index, !mfc.isReplay, true)
    }
    return this.renderPaid(mfc, index, null, true)
  };

  renderSoldOut = () => (
    <div className="mfc__row" onClick={() => this.props.history.push('join-team')}>
      <div className="mfc__row__container">
        <div className="mfc__row__container__right" style={{ width: '100%' }}>
          <div className="mfc__row__container__right__top">
            <div className="mfc__row__container__right__top__label">
              MFC4 Live
            </div>
          </div>
          <div className="mfc__row__container__right__bottom">
            <div className="mfc__row__container__right__bottom__text" style={{ color: '#333333', fontSize: 14 }}>
              les inscriptions au myFIITChallenge4 sont terminées.<br />
              <b>(Clique ici si tu as un code "partenaire")</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  render() {
    const { account } = this.props

    const items = [
      {
        icon: 'fa-home',
        label: 'Accueil',
        link: '/my-fiit-challenges'
      }
    ]

    return (
      <AppContainer>
        <div>
          <Header icon="power-off" account={account} isBackground powerOff isDashboard />
          {/* <Breadcrumb title="ACCUEIL" account={account} /> */ }
          <div className="mfc">
            <div className="mfc__left" style={{ paddingBottom: 0 }}>
              <div className="mfc__left__content">
                <BreadcrumbDesktop items={items} />
                <div className="mfc__left__content__new-title hide-for-mobile">
                  Es-tu prêt(e) à relever ces défis avec nous ?
                </div>
                {
                  account
                  && (
                    <div className="mfc__left__content__mfcs">
                      {
                        account.duo && this.renderModeDuo(account)
                      }
                      {
                        account && account.availableMfcs && account.availableMfcs.sort((a, b) => a.position - b.position).map((item, index) => {
                          if (item.highlighted) {
                            return (
                              <div className="mfc__left__content__mfcs__list">
                                {
                                    this.renderMfc(item, index, false)
                                  }
                              </div>
                            )
                          }
                        })
                      }
                      {
                        account.purchasedItems && account.purchasedItems.length > 0 && (
                          <div className="mfc__left__content__buy">Tes programmes déjà achetés</div>
                        )
                      }
                      <div className="mfc__left__content__mfcs__list">
                        {
                          account.purchasedItems && account.purchasedItems.sort((a, b) => a.position - b.position).map((item, index) => (
                            this.renderMfc(item, index, true)
                          ))
                      }
                      </div>
                      { account && account.freeze && this.renderFreeze() }
                      {
                        (!account.freeze && !!account.availableMfcs.length && (account.availableMfcs.length > 1) || (account.availableMfcs.length === 1 && !account.availableMfcs[0].highlighted)) && (
                        <>
                          <div className="mfc__left__content__buy">{account.purchasedItems.length > 0 ? 'Autres programmes' : 'Programmes'} disponibles</div>
                          <div className="mfc__left__content__mfcs__list">
                            {
                                account && account.availableMfcs && account.availableMfcs.sort((a, b) => a.position - b.position).map((item, index) => {
                                  if (!item.highlighted) {
                                    return (
                                      this.renderMfc(item, index, false)
                                    )
                                  }
                                })
                                }
                          </div>
                        </>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    )
  }
}

export default connect(
  (state) => {
    return ({
      account: state.Facebook.account ? (state.Facebook.account.data || false) : false,
    })
  },
  {
    getMfcs
  }
)(withCookies(withRouter(MfSelect)))
