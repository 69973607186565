import axios from 'axios'
import { uri } from '../../uri'
import { getToken } from '../token'
import config from '../../config'

const getConfig = () => ({
  headers: { Authorization: `Bearer ${getToken('mfc-token')}` }
})

export const getAvailablePdf = id => (dispatch) => {
  dispatch({
    type: 'GET_AVAILABLES_PDFS'
  })

  axios.get(`${uri.uri.pdf.available}/${id}`, getConfig()).then((response) => {

    dispatch({
      type: 'GET_AVAILABLES_PDFS_SUCCESS',
      availablePdfs: response
    })
  }).catch((error) => {
    dispatch({
      type: 'GET_AVAILABLES_PDFS_FAIL',
      availablePdfs: null
    })
  })
}

export const downloadPdf = doc => (dispatch) => {
  dispatch({
    type: 'DOWNLOAD_DOC'
  })


  const filePath = `${config.apiUrl}/pdf-public${doc}`
  const win = window.open(filePath, '_blank')
  win.focus()

  /* const link = document.createElement('a')
  link.href = filePath
  link.setAttribute('target', '_blank')

  if (link.download !== undefined) {
    link.download = doc
  }

  if (document.createEvent) {
    const e = document.createEvent('MouseEvents')
    e.initEvent('click', true, true)
    link.dispatchEvent(e)
    return true
  } */
}
